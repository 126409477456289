import { ref } from '@vue/composition-api'
import { STREET_TYPES } from '@/constants/streetTypes'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'

export function useAddressFormat(address) {
  const corporateRegistrationStore = useCorporateRegistrationStore()
  let formData = [
    { id: 'line1', value: address.addressLine1 || '' },
    { id: 'line2', value: address.addressLine2 || '' },
    { id: 'line3', value: address.addressLine3 || '' },
    // fixed-format address
    { id: 'streetname', value: address.streetname || '' },
    { id: 'streetnumber', value: address.streetnumber || '' },
    { id: 'unit', value: address.unit || '' },
    { id: 'area', value: address.area || '' },
    { id: 'suburb', value: address.suburb || '' },
    // shared
    { id: 'city', value: address.city || '' },
    { id: 'state', value: address.state || '' },
    { id: 'county', value: address.provinceName || address.county || '' },
    { id: 'postalcode', value: address.postCode || address.postalcode || '' },
    { id: 'country', value: address.countryCode || '' },
  ]

  // Set state name
  const getState = corporateRegistrationStore.stateList
  const setStateName = ref([])

  if (getState && getState.length > 0 && getState.length > 0) {
    setStateName.value = getState
      .filter((state) => state.id && state.id === address?.state?.value)
      .map(({ name }) => name)
      .toString()
  }

  // Create a full address
  let getFullAdress = {}
  let oneLineAddress = ''

  // Get data address
  formData.forEach((item) => (getFullAdress[item.id] = item.value))

  // Set Full address

  // scenario 1: adress with free format
  if (getFullAdress.line1.length > 0) {
    oneLineAddress = getFullAdress.line1
  }

  if (getFullAdress.line2.length > 0) {
    oneLineAddress = oneLineAddress + ' ' + getFullAdress.line2
  }

  if (getFullAdress.line3.length > 0) {
    oneLineAddress = oneLineAddress + ' ' + getFullAdress.line3
  }

  // Scenario 2: address with fixed format
  if (getFullAdress.unit.length > 0) {
    oneLineAddress = oneLineAddress + getFullAdress.unit
  }

  if (getFullAdress.streetnumber.length > 0) {
    oneLineAddress = oneLineAddress + '-' + getFullAdress.streetnumber
  }

  if (getFullAdress.streetname.length > 0) {
    oneLineAddress = oneLineAddress + ' ' + getFullAdress.streetname
  }

  if (getFullAdress.suburb.length > 0) {
    oneLineAddress = oneLineAddress + ', ' + getFullAdress.suburb
  }
  // Shared scenarios
  oneLineAddress = oneLineAddress + ', ' + getFullAdress.city
  if (setStateName && setStateName.length > 0 && getState.length !== 0) {
    oneLineAddress = oneLineAddress + ', ' + setStateName
  }

  // county (UK)
  if (getFullAdress.county.length > 0) {
    oneLineAddress = oneLineAddress + ', ' + getFullAdress.county
  }

  oneLineAddress = oneLineAddress + ', ' + getFullAdress.country.toUpperCase()
  oneLineAddress = oneLineAddress + ', ' + getFullAdress.postalcode

  // Capitalize text
  oneLineAddress = oneLineAddress
    .replace(/^\s+|\s+$/g, '')
    .toLocaleLowerCase()
    .replace(/^\w|\s\w/g, (letter) => letter.toUpperCase())

  return oneLineAddress
}

export function extractStreetNameAndStreetType(street) {
  const streetType = extractStreetType(street)
  const streetName = applyStreetType(street, streetType)
  return [streetName, streetType]
}

function extractStreetType(streetName) {
  if (streetName === '') {
    return ''
  }

  const streetTypes = STREET_TYPES
  let parts = streetName.split(' ')
  if (parts.length === 0) {
    return 'null'
  }

  // Last part (word) of streetName is the street type
  let streetType = parts[parts.length - 1]
  if (streetType.length === 1 && parts.length >= 3) {
    // Handle the case when the street type contains 2 words e.g. Avenue North (Ave N)
    const streetTypePrefix = parts[parts.length - 2]
    streetType = `${streetTypePrefix} ${streetType}`
  }
  streetType = streetType.toUpperCase()
  let streetTypeValue = null
  if (streetTypes[streetType]) {
    streetTypeValue = streetTypes[streetType]
  } else {
    for (const key in streetTypes) {
      const value = streetTypes[key]
      if (value && value.toUpperCase() === streetType) {
        streetTypeValue = streetTypes[key]
        break
      }
    }
  }

  return streetTypeValue
}

function applyStreetType(streetName, streetType) {
  if (!streetType || !streetName) {
    return streetName
  }

  let streetNameUpper = streetName.toUpperCase()
  let pos = streetNameUpper.lastIndexOf(streetType.toUpperCase())
  if (pos <= 0) {
    const streetTypes = STREET_TYPES
    for (const key in streetTypes) {
      const value = streetTypes[key]
      if (value && value === streetType) {
        pos = streetNameUpper.lastIndexOf(key.toUpperCase())
        if (pos > 0) {
          break
        }
      }
    }
  }

  // remove street type from street name
  if (pos > 0) {
    return streetName.substr(0, pos).trim()
  }
}
