import { render, staticRenderFns } from "./AuthorizedSignatory.vue?vue&type=template&id=487bf742&scoped=true&"
import script from "./AuthorizedSignatory.vue?vue&type=script&lang=js&"
export * from "./AuthorizedSignatory.vue?vue&type=script&lang=js&"
import style0 from "./AuthorizedSignatory.vue?vue&type=style&index=0&id=487bf742&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487bf742",
  null
  
)

export default component.exports