<template>
  <div>
    <AppDynamicFormField
      v-for="(field, idx) in visibleFields"
      :key="idx"
      :field="field"
      :disabled="disabled"
    >
    </AppDynamicFormField>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppDynamicFormField from '@/components/AppDynamicFormField/AppDynamicFormField'
export default {
  name: 'AppDynamicForm',
  components: {
    AppDynamicFormField,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visibleFields = computed(() => {
      return props.fields.filter((field) => field.isVisible())
    })
    return {
      visibleFields,
    }
  },
}
</script>
<style lang="postcss" scoped></style>
