<template>
  <AppButton :analytics-name="analyticsName" theme="icon" @click="$emit('click')">
    <AppIcon :name="name">
      <Component :is="component" />
    </AppIcon>
  </AppButton>
</template>

<script>
import AppButton from '@en-ui/components/AppButton/AppButton'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import { IconClose, IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import { computed } from '@vue/composition-api'
export const icons = ['x', '<']
export default {
  name: 'AppBackButton',
  components: { AppIcon, AppButton, IconClose },
  props: {
    icon: {
      type: String,
      default: 'x',
      validator: (prop) => icons.indexOf(prop) > -1,
    },
    name: {
      type: String,
      default: 'Close',
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup(props) {
    const component = computed(() => {
      switch (props.icon) {
        case 'x':
          return IconClose
        case '<':
          return IconChevronLeft
      }
    })
    return {
      component,
    }
  },
}
</script>

<style scoped></style>
