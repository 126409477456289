<template>
  <header
    class="flex card-header"
    :class="{ 'card-header--without-title': !$slots.default }"
  >
    <div class="card-header-left">
      <!-- @slot left: For action buttons-->
      <slot name="left"></slot>
    </div>
    <div class="card-header-title">
      <!-- @slot default: For optional title -->
      <slot></slot>
    </div>
    <div class="card-header-right">
      <!-- @slot right: For action buttons -->
      <slot name="right"></slot>
    </div>
  </header>
</template>
<script>
export default {
  name: 'AppModalHeader',
  emits: [],
  props: {},
}
</script>

<style lang="postcss" scoped>
.card-header {
  @apply pt-7 px-7;
  @apply justify-center items-center w-full;

  @apply mb-5;
  &.card-header--without-title {
    @apply -mb-1;
    @apply flex;
  }

  .card-header-title {
    @apply type-h2;
  }

  .card-header-left,
  .card-header-right {
    @apply flex-1;
  }

  .card-header-left {
    @apply flex justify-start;
  }

  .card-header-right {
    @apply flex justify-end;
  }
}

.xs-header {
  @apply py-2 px-4 shadow-ria-1;
}
</style>
