<template>
  <div>
    <AppModal
      v-if="showModal"
      :value="showModal"
      :dismissible="true"
      :content-centered="true"
      :persistent="true"
      class="authorized-personnel-modal"
    >
      <!-- Header -->
      <template v-if="!isAuthorizedDeletedTriggered" #header>
        <AppModalHeader class="authorized-modal" :class="{ 'xs-header': mq.current === 'xs' }">
          <h3 class="type-h3">{{ AuthorizedPersonnelTitle }}</h3>
          <template #right>
            <AppBackButton icon="x" name="Close" @click="goToAuthorizedPersonnel" />
          </template>
        </AppModalHeader>
      </template>

      <!-- Body -->
      <template>
        <!--  Remove authorized personnel -->
        <template v-if="isAuthorizedDeletedTriggered">
          <div class="text-center mt-14 mb-6">
            <template>
              <div class="pb-5 flex justify-center">
                <img :src="require('../../../public/img/icons/warning.svg')" alt="Warning sign" />
              </div>
            </template>
            <h1 class="mb-4 type-h1">Are you sure you want to delete this person?</h1>
            <p class="text-text-gray-primary type-subtitle">
              Their information will be permanently removed from your account
            </p>
          </div>
        </template>

        <!-- Add/edit authorized personnel  -->
        <template v-else>
          <!-- Dynamic form -->
          <AppDynamicForm :fields="formFields"></AppDynamicForm>

          <!-- Address Field -->
          <template v-if="!isSelectedAddress">
            <!-- Address search -->
            <XeAddressSearch
              data-private
              :dropdown-label="'Address'"
              :is-manually-click="true"
              :show-manual-input-prompt="true"
              :country="corpProfileCountry"
              :is-allow-free-input="true"
              :validation-error-message="addressErrorMessage"
              @input="onInput"
              @select="onSelect"
            />
          </template>

          <!-- Selected Address -->
          <template v-else>
            <div class="p-6 mb-6 border border-solid rounded-2xl border-tertiary-grey-darkest">
              <div class="flex">
                <div class="flex flex-col">
                  <AppIcon name="Location">
                    <IconLocation class="text-main-cyan" />
                  </AppIcon>
                </div>
                <div class="ml-2 flex-grow">
                  <h3 class="type-subtitle-bold">Address</h3>
                  <p class="pt-1 type-caption text-gray-primary" data-private>
                    {{ formattedAddress }}
                  </p>
                </div>
                <div class="flex flex-col justify-center cursor-pointer" @click="onAddressClear">
                  <AppIcon name="Edit">
                    <IconEdit class="text-gray-primary" />
                  </AppIcon>
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>

      <!-- Footer -->
      <!-- Desktop/tablet -->
      <template v-if="mq.current !== 'xs'">
        <!-- Delete authorized personnel -->
        <template v-if="isAuthorizedDeletedTriggered">
          <AppButton class="w-full" @click="deleteAuthorizedPersonnel">Continue</AppButton>
          <AppButton
            class="w-full mt-6"
            theme="text"
            @click="setAuthPersonnelStatus({ action: 'edit', id }, false)"
          >
            Cancel
          </AppButton>
        </template>

        <!-- Add/edit authorized personnel -->
        <template v-else>
          <AppButton
            class="w-full"
            :disabled="isSubmitButtonDisabled"
            @click="saveAuthorizedPersonnel"
            >Continue</AppButton
          >
          <AppButton
            v-if="showDeleteButton"
            class="delete-button"
            theme="text"
            @click="setAuthPersonnelStatus({ action: 'delete', id }, true)"
            ><AppIcon name="Edit" class="mr-1.5"> <IconTrashCan /> </AppIcon>Delete Personnel
          </AppButton>
        </template>
      </template>
      <!-- Mobile  -->
      <template v-if="mq.current == 'xs'" #footer>
        <AppModalFooter>
          <!-- Delete authorized personnel -->
          <template v-if="isAuthorizedDeletedTriggered">
            <AppButton theme="text" @click="setAuthPersonnelStatus({ action: 'edit', id }, false)">
              <span class="text-main-black">Cancel</span>
            </AppButton>
            <AppButton @click="deleteAuthorizedPersonnel">Continue</AppButton>
          </template>

          <!-- Add/edit authorized personnel -->
          <template v-else>
            <AppButton
              v-if="showDeleteButton"
              class="delete-button-mobile"
              theme="text"
              @click="setAuthPersonnelStatus({ action: 'delete', id }, true)"
              ><AppIcon name="Edit" class="mr-1.5"> <IconTrashCan /> </AppIcon>Delete Personnel
            </AppButton>
            <AppButton
              class="documentary-evidence-button"
              :disabled="isSubmitButtonDisabled"
              @click="saveAuthorizedPersonnel"
            >
              Continue
            </AppButton>
          </template>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
// Helpers
import { computed, ref, watch } from '@vue/composition-api'
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'
import { useRouter } from '@/composables/useRouter'
import { useStore } from '@/composables/useStore'
// App components
import AppModal from '@/components/AppModal/AppModal'
import AppModalHeader from '@/components/AppModalHeader/AppModalHeader.vue'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter.vue'
import AppButton from '@/components/AppButton/AppButton.vue'
import AppBackButton from '@en-ui/components/AppBackButton/AppBackButton'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconEdit, IconLocation, IconTrashCan } from '@moneytransfer.ui/euronet-icons'
import XeAddressSearch from '@/components/XeAddressSearch/XeAddressSearch'

// Dynamic form
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import { useAuthorizedPersonnelForm } from '@/forms/AuthorizedPersonnelForm'
// Mock data
import mockData from '@/data/pendingActions/authorizedSignatoryDetails'

// Address formatter
import { useAddressFormat } from '@/composables/useAddress'
import { useDashboardStore } from '@/stores/dashboard'
import { useCountriesStore } from '@/stores/countries'

export default {
  setup(props) {
    const router = useRouter()
    const store = useStore()

    const mq = useMediaQuery()
    const dashboardStore = useDashboardStore()
    const countriesStore = useCountriesStore()

    const showDeleteButton = ref(false)
    const isAuthorizedDeletedTriggered = ref(false)
    const dateOfBirth = ref(null)

    const corpProfile = dashboardStore.corpProfile
    const corpProfileCountry = corpProfile.registrationAddress.country.toUpperCase()

    const userFieldsForCountry = mockData[corpProfileCountry]

    const AuthorizedPersonnelTitle = ref(
      countriesStore.getCountrySpecificText('AuthorizedPersonnelTitle', corpProfileCountry)
    )

    // Handle dynamic form data¡
    const {
      setup: setupFields,
      // isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useAuthorizedPersonnelForm(store)
    setupFields(userFieldsForCountry)

    const setPersistedValue = (value, target) => {
      const firstNameField = formFields.value.find((field) => field.id === target)
      firstNameField.value = value
    }

    // Get selected address
    const formattedAddress = ref('')
    const isSelectedAddress = ref(false)
    const isAddressReady = ref(false)
    const addressErrorMessage = ref('')

    const onInput = (address) => {
      const trimmedAddress = address.trim()
      formattedAddress.value = trimmedAddress
      if (trimmedAddress) {
        isAddressReady.value = true
      } else {
        isAddressReady.value = false
      }
    }

    const onSelect = (address) => {
      formattedAddress.value = useAddressFormat(address, store)
      isSelectedAddress.value = true
      isAddressReady.value = true
    }

    const onAddressClear = () => {
      formattedAddress.value = ''
      isSelectedAddress.value = false
      isAddressReady.value = false
    }

    watch(formattedAddress, (formattedAddress) => {
      validateAddress(formattedAddress)
    })
    const countryConfig = computed(() =>
      countriesStore.getCountryConfigByCountryCode(corpProfileCountry)
    )
    const validateAddress = (address) => {
      const addressSearchLoadingRegex = /.+?\|.+?\|.+?\|.+?/
      const isAddressSearchLoading = addressSearchLoadingRegex.test(address)
      if (isAddressSearchLoading) {
        isAddressReady.value = false
        return
      }

      const regex = new RegExp(countryConfig.value.addressRegex)
      const isValid = regex.test(address)
      if (isValid) {
        addressErrorMessage.value = ''
      } else {
        addressErrorMessage.value = 'The address you entered is invalid. Please try again'
      }
    }

    if (props?.authorizedPersonnel) {
      const authorizedPersonnel = props?.authorizedPersonnel
      showDeleteButton.value = true

      let fieldIds = userFieldsForCountry.fields.map((field) => field.id)

      fieldIds.forEach((id) => {
        let fieldValue = authorizedPersonnel[id]
        setPersistedValue(fieldValue, id)
      })

      if (authorizedPersonnel.address) {
        formattedAddress.value = authorizedPersonnel.address
        isSelectedAddress.value = true
        isAddressReady.value = true
      }
    }

    // Handle current user logic for params
    const getField = (form, target) => {
      return form.value.find((field) => field.id === target)
    }

    const saveAuthorizedPersonnel = () => {
      const getPersonnelType = getField(formFields, 'personneltype').value
      const formData = {}

      let fieldIds = userFieldsForCountry.fields.map((field) => field.id)

      fieldIds.forEach((id) => {
        formData[id] = getField(formFields, id).value

        if (id === 'personneltype') {
          let optionValues = userFieldsForCountry.fields
            .find((field) => field.id === id)
            .checkboxOptions.map((option) => option.value)
          optionValues.forEach((value) => {
            formData[value] = getPersonnelType.some(
              (personnelType) => personnelType.value === value
            )
          })
        }
      })

      formData['address'] = formattedAddress.value

      const isEditingPersonnel = props.action === 'edit'

      // go to AdditionalInfo page if (adding a owner or changing from director to owner) and (ownership limit exceeded)
      const isOwnershipLimitExceeded = dashboardStore.getIsOwnershipLimitExceeded
      if (isOwnershipLimitExceeded && formData.isUbo) {
        if ((isEditingPersonnel && !props?.authorizedPersonnel.isUbo) || !isEditingPersonnel) {
          return goToAuthorizedPersonnel()
        }
      }

      if (isEditingPersonnel) {
        dashboardStore.setAuthorizedPersonnelByIndex({
          authorizedPersonnel: formData,
          index: props.index,
        })
      } else {
        dashboardStore.setAuthorizedPersonnel(formData)
      }
      goToAuthorizedPersonnel()
    }

    const deleteAuthorizedPersonnel = () => {
      if (props.index !== null) {
        dashboardStore.deleteAuthorizedPersonnelByIndex({ index: props.index })
      }
      goToAuthorizedPersonnel()
    }

    const goToAuthorizedPersonnel = () => {
      router.push({ name: 'AuthorizedPersonnel' })
    }

    // Validation form
    const isSubmitButtonDisabled = computed(() => {
      const returnValue = Boolean(
        !hasFormFields.value ||
          isFormInvalid.value ||
          !isAddressReady.value ||
          addressErrorMessage.value ||
          dateOfBirth.value
      )
      return returnValue
    })

    const setAuthPersonnelStatus = (options, isAuthPersonnel) => {
      const { action, id } = options
      const { index, authorizedPersonnel } = props
      isAuthorizedDeletedTriggered.value = isAuthPersonnel

      router.push({
        // Go to the document page (Modal)
        name: 'AuthorizedSignatory',
        // Passing props data trough param routes
        params: { action, id, index, authorizedPersonnel },
      })
    }

    formFields.value.forEach((field, i) => {
      if (field.component.name === 'date') {
        formFields.value[i]['listeners'] = {
          validation: (componentValidation) => {
            dateOfBirth.value = componentValidation['$anyInvalid']
          },
        }
      }
    })

    return {
      onSelect,
      saveAuthorizedPersonnel,
      deleteAuthorizedPersonnel,
      goToAuthorizedPersonnel,
      setAuthPersonnelStatus,
      formFields,
      isSubmitButtonDisabled,
      mq,
      formattedAddress,
      isSelectedAddress,
      onInput,
      onAddressClear,
      showDeleteButton,
      addressErrorMessage,
      corpProfileCountry,
      isAuthorizedDeletedTriggered,
      AuthorizedPersonnelTitle,
    }
  },
  components: {
    AppModal,
    AppButton,
    AppModalHeader,
    AppModalFooter,
    AppBackButton,
    IconEdit,
    AppDynamicForm,
    XeAddressSearch,
    AppIcon,
    IconLocation,
    IconTrashCan,
  },
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    authorizedPersonnel: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    action: {
      type: String,
      default: '',
    },
    id: {
      type: [Number, String],
      default: null,
    },
  },
  beforeRouteEnter: (to, _from, next) => {
    if (to.params.action === 'edit' && !to.params.authorizedPersonnel) {
      next({ name: 'AdditionalInfo' })
    }
    next()
  },
}
</script>

<style scoped lang="postcss">
.continue-button {
  @apply w-full my-2 !important;
}

.delete-button {
  @apply type-button text-text-red w-full my-2 py-2 !important;

  &-mobile {
    @apply type-button text-text-red w-full !important;
  }
}

.authorized-modal {
  @apply p-1;

  @screen sm {
    @apply p-0;
  }
}

.authorized-personnel-modal {
  &::v-deep .overlay-content {
    height: 100%;
  }
  &::v-deep .card {
    @screen sm {
      @apply mt-12;
      width: 440px;
    }
    .card-header {
      @screen sm {
        @apply mb-9 pt-9 px-9;
        @apply border-none;
      }
      @apply border-b border-gray-lighter;
    }
    .card-content {
      @apply pt-0;
    }
  }
}
</style>
