export const STREET_TYPES = {
  ACC: 'Access',
  ACCS: 'Access',
  ALY: 'Alley',
  AL: 'Alley',
  ALWY: 'Alleyway',
  APP: 'Approach',
  APCH: 'Approach',
  ARC: 'Arcade',
  ARTY: 'Artery',
  ARTL: 'Arterial',
  ANC: 'Anchorage',
  ANCH: 'Anchorage',
  ANX: 'Anex',
  AVE: 'Avenue',
  AV: 'Avenue',
  'AVE EAST': 'Avenue East',
  'AVE E': 'Avenue East',
  'AV EAST': 'Avenue East',
  'AV E': 'Avenue East',
  'AVE WEST': 'Avenue West',
  'AVE W': 'Avenue West',
  'AV WEST': 'Avenue West',
  'AV W': 'Avenue West',
  'AVE NORTH': 'Avenue North',
  'AVE N': 'Avenue North',
  'AV NORTH': 'Avenue North',
  'AV N': 'Avenue North',
  BA: 'Banan',
  BLDG: 'Building',
  BYP: 'By-Pass',
  BSN: 'Basin',
  BCH: 'Beach',
  BND: 'Bend',
  BWLK: 'Boardwalk',
  BLK: 'Block',
  'BLVD EAST': 'Boulevard East',
  'BLVD NORTH': 'Boulevard North',
  'BLVD SOUTH': 'Boulevard South',
  'BLVD WEST': 'Boulevard West',
  'BLVD E': 'Boulevard East',
  'BLVD N': 'Boulevard North',
  'BLVD S': 'Boulevard South',
  'BLVD W': 'Boulevard West',
  BLVD: 'Boulevard',
  BL: 'Bowl',
  BRC: 'Brace',
  BR: 'Brae',
  BRK: 'Break',
  BRDG: 'Bridge',
  BRG: 'Bridge',
  BDWY: 'Broadway',
  BWAY: 'Broadway',
  BWY: 'Broadway',
  BPS: 'Bypass',
  CANT: 'Canton',
  'CANT.': 'Canton',
  CARR: 'Carre',
  CAR: 'Carrefour',
  CHE: 'Chemin',
  CONC: 'Concession',
  CRNRS: 'Corners',
  CROIS: 'Croissant',
  CDS: 'Cul-De-Sac',
  CSWY: 'Causeway',
  CWAY: 'Causeway',
  CEN: 'Centre',
  CTR: 'Centre',
  CNWY: 'Centreway',
  CH: 'Chase',
  CIR: 'Circle',
  'CIR EAST': 'Circle East',
  'CIR NORTH': 'Circle North',
  'CIR SOUTH': 'Circle South',
  'CIR WEST': 'Circle West',
  'CIR E': 'Circle East',
  'CIR N': 'Circle North',
  'CIR S': 'Circle South',
  'CIR W': 'Circle West',
  CI: 'Circuit',
  CCT: 'Circuit',
  CRC: 'Circus',
  'CRC EAST': 'Circus East',
  'CRC NORTH': 'Circus North',
  'CRC SOUTH': 'Circus South',
  'CRC WEST': 'Circus West',
  'CRCS EAST': 'Circus East',
  'CRCS NORTH': 'Circus North',
  'CRCS SOUTH': 'Circus South',
  'CRCS WEST': 'Circus West',
  'CRC E': 'Circus East',
  'CRC N': 'Circus North',
  'CRC S': 'Circus South',
  'CRC W': 'Circus West',
  'CRCS E': 'Circus East',
  'CRCS N': 'Circus North',
  'CRCS S': 'Circus South',
  'CRCS W': 'Circus West',
  CL: 'Close',
  'CL EAST': 'Close East',
  'CL NORTH': 'Close North',
  'CL SOUTH': 'Close South',
  'CL WEST': 'Close West',
  'CL E': 'Close East',
  'CL N': 'Close North',
  'CL S': 'Close South',
  'CL W': 'Close West',
  CMN: 'Common',
  COMM: 'Common',
  CON: 'Concourse',
  CPS: 'Copse',
  CNR: 'Corner',
  COR: 'Corner',
  CSO: 'Corso',
  CRSE: 'Course',
  CT: 'Court',
  'CT EAST': 'Court East',
  'CT NORTH': 'Court North',
  'CT SOUTH': 'Court South',
  'CT WEST': 'Court West',
  'CT E': 'Court East',
  'CT N': 'Court North',
  'CT S': 'Court South',
  'CT W': 'Court West',
  CRT: 'Court',
  'CRT EAST': 'Court East',
  'CRT NORTH': 'Court North',
  'CRT SOUTH': 'Court South',
  'CRT WEST': 'Court West',
  'CRT E': 'Court East',
  'CRT N': 'Court North',
  'CRT S': 'Court South',
  'CRT W': 'Court West',
  CTS: 'Courts',
  CYD: 'Courtyard',
  CTYD: 'Courtyard',
  CRES: 'Crescent',
  'CRES EAST': 'Crescent East',
  'CRES NORTH': 'Crescent North',
  'CRES SOUTH': 'Crescent South',
  'CRES WEST': 'Crescent West',
  'CRES E': 'Crescent East',
  'CRES N': 'Crescent North',
  'CRES S': 'Crescent South',
  'CRES W': 'Crescent West',
  CR: 'Crescent',
  'CR EAST': 'Crescent East',
  'CR NORTH': 'Crescent North',
  'CR SOUTH': 'Crescent South',
  'CR WEST': 'Crescent West',
  'CR E': 'Crescent East',
  'CR N': 'Crescent North',
  'CR S': 'Crescent South',
  'CR W': 'Crescent West',
  CRST: 'Crest',
  CS: 'Cross',
  CRSS: 'Cross',
  XING: 'Crossing',
  CSG: 'Crossing',
  CRD: 'Crossroad',
  XRD: 'Crossroad',
  XWAY: 'Crossway',
  COWY: 'Crossway',
  CUTT: 'Cutting',
  DEVN: 'Deviation',
  DIVERS: 'Diversion',
  DSTR: 'Distributor',
  DV: 'Divide',
  DIV: 'Divide',
  DOM: 'Domain',
  DN: 'Downs',
  DR: 'Drive',
  'DR EAST': 'Drive East',
  'DR NORTH': 'Drive North',
  'DR SOUTH': 'Drive South',
  'DR WEST': 'Drive West',
  'DR E': 'Drive East',
  'DR N': 'Drive North',
  'DR S': 'Drive South',
  'DR W': 'Drive West',
  DRV: 'Drive',
  'DRV EAST': 'Drive East',
  'DRV NORTH': 'Drive North',
  'DRV SOUTH': 'Drive South',
  'DRV WEST': 'Drive West',
  'DRV E': 'Drive East',
  'DRV N': 'Drive North',
  'DRV S': 'Drive South',
  'DRV W': 'Drive West',
  DRWY: 'Driveway',
  DVWY: 'Driveway',
  DWY: 'Driveway',
  E: 'East',
  ECH: 'Echangeur',
  EDG: 'Edge',
  ELB: 'Elbow',
  ENT: 'Entrance',
  ESP: 'Esplanade',
  'ESP EAST': 'Esplanade East',
  'ESP NORTH': 'Esplanade North',
  'ESP SOUTH': 'Esplanade South',
  'ESP WEST': 'Esplanade West',
  'ESP E': 'Esplanade East',
  'ESP N': 'Esplanade North',
  'ESP S': 'Esplanade South',
  'ESP W': 'Esplanade West',
  EST: 'Estate',
  ESTS: 'Estates',
  EXP: 'Expressway',
  EXPY: 'Expressway',
  EXPWY: 'Expressway',
  EX: 'Extension',
  EXT: 'Extension',
  FWAY: 'Fairway',
  FY: 'Fairway',
  FRD: 'Ford',
  FIT: 'Firetrail',
  FTRK: 'Fire Track',
  FL: 'Flat',
  FLTS: 'Flats',
  FOLW: 'Follow',
  FTWY: 'Footway',
  FSHR: 'Foreshore',
  FRST: 'Forest',
  FORM: 'Formation',
  FRWY: 'Freeway',
  FWY: 'Freeway',
  FRNT: 'Front',
  FR: 'Frontage',
  FRTG: 'Frontage',
  GDN: 'Garden',
  GDNS: 'Gardens',
  GA: 'Gate',
  GTE: 'Gates',
  GWY: 'Gateway',
  GTWY: 'Gateway',
  GLD: 'Glade',
  GLDS: 'Glades',
  GLN: 'Glen',
  GRA: 'Grange',
  GN: 'Green',
  GRN: 'Green',
  GRND: 'Ground',
  GRNDS: 'Grounds',
  GR: 'Grove',
  'GR EAST': 'Grove East',
  'GR NORTH': 'Grove North',
  'GR SOUTH': 'Grove South',
  'GR WEST': 'Grove West',
  'GR E': 'Grove East',
  'GR N': 'Grove North',
  'GR S': 'Grove South',
  'GR W': 'Grove West',
  GRV: 'Grove',
  'GRV EAST': 'Grove East',
  'GRV NORTH': 'Grove North',
  'GRV SOUTH': 'Grove South',
  'GRV WEST': 'Grove West',
  'GRV E': 'Grove East',
  'GRV N': 'Grove North',
  'GRV S': 'Grove South',
  'GRV W': 'Grove West',
  GLY: 'Gully',
  HGTS: 'Heights',
  HTS: 'Heights',
  HT: 'Heights',
  HRD: 'Highroad',
  HSE: 'House',
  HVN: 'Haven',
  'HWY EAST': 'Highway East',
  'HWY NORTH': 'Highway North',
  'HWY SOUTH': 'Highway South',
  'HWY WEST': 'Highway West',
  'HWY E': 'Highway East',
  'HWY N': 'Highway North',
  'HWY S': 'Highway South',
  'HWY W': 'Highway West',
  HWY: 'Highway',
  HL: 'Hill',
  HOLW: 'Hollow',
  IMP: 'Impasse',
  IS: 'Island',
  INTG: 'Interchange',
  JCT: 'Junction',
  JNC: 'Junction',
  JCTN: 'Junction',
  KNL: 'Knoll',
  LNDG: 'Landing',
  LDG: 'Landing',
  LANDNG: 'Landing',
  LN: 'Lane',
  'LN WEST': 'Lane West',
  'LN EAST': 'Lane East',
  'LN NORTH': 'Lane North',
  'LN SOUTH': 'Lane South',
  'LN W': 'Lane West',
  'LN E': 'Lane East',
  'LN N': 'Lane North',
  'LN S': 'Lane South',
  LNWY: 'Laneway',
  LIT: 'Little',
  LT: 'Little',
  LKT: 'Lookout',
  LP: 'Loop',
  LOW: 'Lower',
  LWR: 'Lower',
  MNR: 'Manor',
  MDR: 'Meander',
  MNDR: 'Meander',
  MTE: 'Montee',
  MTN: 'Mountain',
  MTWY: 'Motorway',
  MWY: 'Motorway',
  MT: 'Mount',
  NK: 'Nook',
  NORTH: 'North',
  ORCH: 'Orchard',
  OUT: 'Outlook',
  OTLK: 'Outlook',
  PDE: 'Parade',
  'PDE EAST': 'Parade East',
  'PDE NORTH': 'Parade North',
  'PDE SOUTH': 'Parade South',
  'PDE WEST': 'Parade West',
  'PDE E': 'Parade East',
  'PDE N': 'Parade North',
  'PDE S': 'Parade South',
  'PDE W': 'Parade West',
  PK: 'Park',
  'PK EAST': 'Park East',
  'PK NORTH': 'Park North',
  'PK SOUTH': 'Park South',
  'PK WEST': 'Park West',
  'PK E': 'Park East',
  'PK N': 'Park North',
  'PK S': 'Park South',
  'PK W': 'Park West',
  PKLD: 'Parklands',
  PKWY: 'Parkway',
  PWY: 'Parkway',
  PS: 'Pass',
  PSGE: 'Passage',
  PWAY: 'Pathway',
  PEN: 'Peninsula',
  PNES: 'Pines',
  PVT: 'Private',
  PL: 'Place',
  'PL EAST': 'Place East',
  'PL NORTH': 'Place North',
  'PL SOUTH': 'Place South',
  'PL WEST': 'Place West',
  'PL E': 'Place East',
  'PL N': 'Place North',
  'PL S': 'Place South',
  'PL W': 'Place West',
  PLAT: 'Plateau',
  PLZ: 'Plaza',
  PKT: 'Pocket',
  PT: 'Point',
  PNT: 'Point',
  PRT: 'Port',
  PRM: 'Promenade',
  PROM: 'Promenade',
  QUAD: 'Quadrangle',
  QDGL: 'Quadrangle',
  QDRT: 'Quadrant',
  QD: 'Quadrant',
  QY: 'Quay',
  'QY EAST': 'Quay East',
  'QY NORTH': 'Quay North',
  'QY SOUTH': 'Quay South',
  'QY WEST': 'Quay West',
  'QY E': 'Quay East',
  'QY N': 'Quay North',
  'QY S': 'Quay South',
  'QY W': 'Quay West',
  QYS: 'Quays',
  RMBL: 'Ramble',
  RGE: 'Range',
  RCH: 'Reach',
  RES: 'Reserve',
  RST: 'Rest',
  RT: 'Retreat',
  RTN: 'Return',
  RDG: 'Ridge',
  RGWY: 'Ridgeway',
  ROWY: 'Right Of Way',
  RIV: 'River',
  RVWY: 'Riverway',
  RVRA: 'Riviera',
  RD: 'Road',
  'RD EAST': 'Road East',
  'RD NORTH': 'Road North',
  'RD SOUTH': 'Road South',
  'RD WEST': 'Road West',
  'RD E': 'Road East',
  'RD N': 'Road North',
  'RD S': 'Road South',
  'RD W': 'Road West',
  RDS: 'Roads',
  RDSD: 'Roadside',
  RDWY: 'Roadway',
  RDPT: 'Rond-Point',
  RNDE: 'Ronde',
  RSBL: 'Rosebowl',
  RTY: 'Rotary',
  RND: 'Round',
  RTE: 'Route',
  RLE: 'Ruelle',
  R: 'Rue',
  'R EST': 'Rue Est',
  'R NORD': 'Rue Nord',
  'R NORD-EST': 'Rue Nord Est',
  'R NORD-OUEST': 'Rue Nord-Quest',
  'R OUEST': 'Rue Quest',
  'R SUD': 'Rue Sud',
  'R SUD-OUEST': 'Rue Sud-Quest',
  'R SUD-EST': 'Rue Sud-Est',
  'R E': 'Rue Est',
  'R N': 'Rue Nord',
  'R NE': 'Rue Nord Est',
  'R NO': 'Rue Nord-Quest',
  SENT: 'Sentier',
  SHUN: 'Shunt',
  SWY: 'Service Way',
  SVWY: 'Service Way',
  SDNG: 'Siding',
  SLPE: 'Slope',
  SND: 'Sound',
  S: 'South',
  SQ: 'Square',
  'SQ EAST': 'Square East',
  'SQ NORTH': 'Square North',
  'SQ SOUTH': 'Square South',
  'SQ WEST': 'Square West',
  'SQ E': 'Square East',
  'SQ N': 'Square North',
  'SQ S': 'Square South',
  'SQ W': 'Square West',
  SD: 'Strand',
  STRA: 'Strand',
  SH: 'State Highway',
  ST: 'Street',
  'ST EAST': 'Street East',
  'ST NORTH': 'Street North',
  'ST SOUTH': 'Street South',
  'ST WEST': 'Street West',
  'ST E': 'Street East',
  'ST N': 'Street North',
  'ST S': 'Street South',
  'ST W': 'Street West',
  'ST NE': 'Street NE',
  'ST SE': 'Street SE',
  'ST SW': 'Street SW',
  'ST NW': 'Street NW',
  STRP: 'Strip',
  SBWY: 'Subway',
  SUBDIV: 'Subdivision',
  SUBD: 'Subdivision',
  TSSE: 'Terrasse',
  THICK: 'Thicket',
  TWR: 'Tower',
  TLINE: 'Townline',
  TN: 'Tarn',
  TER: 'Terrace',
  'TER EAST': 'Terrace East',
  'TER WEST': 'Terrace West',
  'TER E': 'Terrace East',
  'TER W': 'Terrace West',
  TCE: 'Terrace',
  'TCE EAST': 'Terrace East',
  'TCE WEST': 'Terrace West',
  'TCE E': 'Terrace East',
  'TCE W': 'Terrace West',
  TWAY: 'Throughway',
  TRWY: 'Throughway',
  THFR: 'Thoroughfare',
  THOR: 'Thoroughfare',
  TLWY: 'Tollway',
  TWY: 'Tollway',
  TWRS: 'Towers',
  TR: 'Track',
  'TR NORTH': 'Track North',
  'TR SOUTH': 'Track South',
  'TR N': 'Track North',
  'TR S': 'Track South',
  TRK: 'Track',
  'TRK NORTH': 'Track North',
  'TRK SOUTH': 'Track South',
  'TRK N': 'Track North',
  'TRK S': 'Track South',
  TRL: 'Trail',
  TRLR: 'Trailer',
  TRI: 'Triangle',
  TKWY: 'Trunkway',
  TPKE: 'Turnpike',
  TRNABT: 'Turnabout',
  UPAS: 'Underpass',
  UP: 'Upper',
  UPR: 'Upper',
  VA: 'Vale',
  VY: 'Valley',
  VLY: 'Valley',
  VLG: 'Village',
  VIA: 'Viaduct',
  VW: 'View',
  VWS: 'Views',
  VLLS: 'Villas',
  VSTA: 'Vista',
  W: 'West',
  WK: 'Walk',
  WLK: 'Walk',
  'WK EAST': 'Walk East',
  'WK NORTH': 'Walk North',
  'WK SOUTH': 'Walk South',
  'WK WEST': 'Walk West',
  'WK E': 'Walk East',
  'WK N': 'Walk North',
  'WK S': 'Walk South',
  'WK W': 'Walk West',
  'WLK EAST': 'Walk East',
  'WLK NORTH': 'Walk North',
  'WLK SOUTH': 'Walk South',
  'WLK WEST': 'Walk West',
  'WLK E': 'Walk East',
  'WLK N': 'Walk North',
  'WLK S': 'Walk South',
  'WLK W': 'Walk West',
  WKWY: 'Walkway',
  WTR: 'Waters',
  WY: 'Way',
  'WY EAST': 'Way East',
  'WY NORTH': 'Way North',
  'WY SOUTH': 'Way South',
  'WY WEST': 'Way West',
  'WY E': 'Way East',
  'WY N': 'Way North',
  'WY S': 'Way South',
  'WY W': 'Way West',
  WYS: 'Ways',
  WHRF: 'Wharf',
  WYN: 'Wynd',
  YD: 'Yard',
}
