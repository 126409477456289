<template>
  <XeInputDropdown
    ref="input"
    v-debounce:400ms="onTextInput"
    :page-name="pageName"
    :dropdown-label="dropdownLabel"
    option-label="text"
    option-label-subtitle="description"
    option-value="id"
    :show-clear="false"
    :options="addressSearchResultList"
    :placeholder="`Enter ${dropdownLabel.toLowerCase() || 'address'}`"
    :input-editable="true"
    :show-arrow="false"
    :loading="showLoadingSpinner"
    :show-editable-loading="showEditableLoading"
    :no-options-message="noOptionsMessage"
    :validation-error-message="validationErrorMessage"
    @input="onOptionSelected($refs, $event)"
  >
    <template #custom-dropdown-footer-option>
      <div v-if="!isManuallyClick" class="custom-dropdown-footer">
        Don't see your address?
        <a class="custom-footer-button" @click="onEnterManuallyClick">Enter it manually</a>
      </div>
      <div v-else-if="shouldShowManualInputPrompt" class="custom-dropdown-footer">
        <div class="footer-main-captions">
          <p class="type-caption">We couldn't find this address</p>
          <p class="type-caption">Make sure you enter your complete address</p>
        </div>
        <p class="footer-bottom-captions">Street number, Street name, City, State, Zip code</p>
      </div>
    </template>
  </XeInputDropdown>
</template>

<script>
/**
 * TODO: Lint this file
 * TODO: Improve behavior based on data, not events
 */
import { reactive, ref, computed, onMounted } from '@vue/composition-api'
import { useMediaQuery } from '@/composables/useMediaQuery'

import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'

export default {
  name: 'XeAddressSearch',
  components: {
    XeInputDropdown,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    pageName: {
      type: String,
      default: '',
    },
    dropdownLabel: {
      type: String,
      default: '',
    },
    isManuallyClick: {
      type: Boolean,
      default: false,
    },
    isCountrySearch: {
      type: Boolean,
      default: true,
    },
    country: {
      type: String,
      default: '',
    },
    showManualInputPrompt: {
      type: Boolean,
      default: false,
    },
    isAllowFreeInput: {
      type: Boolean,
      default: false,
    },
    validationErrorMessage: {
      type: String,
      default: '',
    },
  },
  emit: ['select', 'manual', 'searchFinish', 'input'],
  setup(props, { emit }) {
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()

    const noOptionsMessageChoices = {
      required: 'Please type at least 1 character',
      noResult: 'No results found',
      tooManyResult: 'Too many results. Please use more specific keywords',
    }

    const showLoadingSpinner = ref(false)
    const showEditableLoading = ref(false)
    const noOptionsMessage = ref(noOptionsMessageChoices.required)
    const country = companyDetailsStore.companyCountry
    const addressSearchResultList = ref([])
    const inputValue = ref('')
    const input = ref(null)

    // Computer property to handle no result from search
    const shouldShowManualInputPrompt = computed(() => {
      return Boolean(
        props.showManualInputPrompt &&
          !showLoadingSpinner.value &&
          addressSearchResultList.value.length === 0 &&
          inputValue.value.length > 0
      )
    })

    let groupedSearch = []
    let lastSearch = ''

    // Get duplicated id's
    const isDuplicatedId = (searchIds) =>
      searchIds.some((item, idx) => searchIds.indexOf(item) !== idx)

    const onTextInput = async (query) => {
      query = query.trim()
      addressSearchResultList.value = []
      noOptionsMessage.value = ''

      lastSearch = query
      if (query.length) {
        showLoadingSpinner.value = true
        try {
          const date = new Date()

          const getAddressRequestObject = {
            searchTerm: query,
            country: props.isCountrySearch ? (props.country ? props.country : country) : '',
          }

          addressSearchResultList.value = await corporateRegistrationStore.fetchAddressSearchResult(
            getAddressRequestObject
          )

          if (lastSearch !== query) {
            return
          }

          addressSearchResultList.value.duration = Math.floor(new Date().getTime() - date.getTime())
          emit('searchFinish', addressSearchResultList.value)
          showLoadingSpinner.value = false
          // Show too much message if groups are more than 1
          groupedSearch = addressSearchResultList.value.filter((address) =>
            address.description.endsWith('Addresses')
          )
          if (groupedSearch.length > 1) {
            addressSearchResultList.value = addressSearchResultList.value.filter(
              (address) => !address.description.endsWith('Addresses')
            )
            noOptionsMessage.value = noOptionsMessageChoices.tooManyResult
          }
          // Set no result message if data is = 0 and different group search
          if (addressSearchResultList.value.length === 0 && !(groupedSearch.length > 1)) {
            noOptionsMessage.value = noOptionsMessageChoices.noResult
          }
        } catch (ex) {
          appStore.logException({
            text: 'Exception during searching for address',
            exception: ex,
          })
          if (lastSearch === query) {
            showLoadingSpinner.value = false
            appStore.messageBoxGenericError()
          }
        }
      } else {
        noOptionsMessage.value = noOptionsMessageChoices.required
        showLoadingSpinner.value = false
      }
    }

    const onOptionSelected = async (refs, selectedId) => {
      inputValue.value = selectedId
      if (props.isAllowFreeInput) {
        emit('input', selectedId)
      }

      const selectedAddress = addressSearchResultList.value.find(
        (address) => address.id === selectedId
      )
      if (selectedAddress) {
        let showItems = false
        showEditableLoading.value = true
        if (selectedAddress.type === 'Address' || selectedAddress.type === 'Item') {
          let addrDetails
          if (selectedAddress.type === 'Item') {
            addrDetails = [selectedAddress]
          } else {
            try {
              addrDetails = await corporateRegistrationStore.fetchAddressDetails({
                addressId: selectedId,
              })
            } catch (ex) {
              addressSearchResultList.value = []
              noOptionsMessage.value = noOptionsMessageChoices.noResult
              showItems = true
              // Return error
              appStore.logException({
                text: 'Address not found',
                exception: ex,
              })
            }
          }

          // Delete duplicated search
          if (isDuplicatedId(addrDetails.map((item) => item.id))) {
            addrDetails = Object.values(
              addrDetails.filter(
                (item) =>
                  item.language === 'ENG' ||
                  item.language === 'FIN' ||
                  item.language === 'SPA' ||
                  item.language === 'GLE'
              )
            )
          }
          if (addrDetails.length >= 1) {
            if (addrDetails[0].addressLine1.length > 0 || addrDetails[0].buildingName.length > 0) {
              emit('select', addrDetails[0])
            } else {
              addressSearchResultList.value = []
              noOptionsMessage.value = noOptionsMessageChoices.noResult
              showItems = true
            }
          } else {
            addressSearchResultList.value = []
            noOptionsMessage.value = noOptionsMessageChoices.noResult
            showItems = true
          }
        } else {
          refs.input.setInputValue(selectedAddress.text)
          addressSearchResultList.value = await corporateRegistrationStore.fetchAddressSearchResult(
            { searchContext: selectedId, searchTerm: lastSearch, country }
          )
          showItems = true
        }
        showEditableLoading.value = false
        if (showItems) {
          window.setTimeout(() => {
            refs.input.show()
          }, 100)
        }
      }
    }

    const onEnterManuallyClick = () => {
      emit('manual')
    }

    onMounted(async () => {
      // Set default value and display result for non-free-format address
      if (props.value && props.value.length > 0) {
        inputValue.value = props.value
        input.value.setInputValue(props.value)
        await onTextInput(props.value)
        input.value.show()
      }
    })

    return {
      addressSearchResultList,
      noOptionsMessage,
      onEnterManuallyClick,
      onOptionSelected,
      onTextInput,
      showEditableLoading,
      showLoadingSpinner,
      shouldShowManualInputPrompt,
      mq: reactive(useMediaQuery()),
      input,
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-enter-section {
  @apply flex justify-center;
  @apply text-base;

  .button {
    @apply ml-1;

    ::v-deep &.button--text {
      .button-inner {
        @apply p-0;
      }
    }

    &:focus,
    &:hover {
      @apply bg-transparent;
    }
  }
}

.footer-main-captions {
  @apply pb-2 text-gray-primary type-caption;
}

.footer-bottom-captions {
  @apply text-text-gray-secondary type-helper;
}

.custom-dropdown-footer {
  @apply px-4 py-2.5;
  @apply type-caption;
  @apply cursor-default;

  .custom-footer-button {
    @apply text-blue;
    @apply cursor-pointer;
  }
}
</style>
